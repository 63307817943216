<template>
  <div>
    <b-card title="Form">
      <b-form>

<!--        input text-->
        <b-form-group>
          <b-form-input
              type="text"
              placeholder="Input text"
          ></b-form-input>
        </b-form-group>
        <hr>

<!--        datepicker-->
        <b-form-group>
          <b-form-datepicker id="example-datepicker" class="mb-2"></b-form-datepicker>
        </b-form-group>
        <hr>

<!--        timepicker-->
        <b-form-group>
          <b-form-timepicker locale="en"></b-form-timepicker>
        </b-form-group>
        <hr/>

<!--        tags-->
        <b-form-group>
          <b-form-tags input-id="tags-basic"></b-form-tags>
        </b-form-group>
        <hr/>

<!--        textarea-->
        <b-form-group>
          <b-form-textarea
              id="textarea"
              placeholder="Textarea..."
              rows="3"
              max-rows="6"
          ></b-form-textarea>
        </b-form-group>
        <hr/>

<!--        checkbox-->
        <b-form-group>
          <b-form-checkbox-group>
            <b-form-checkbox value="1">Value 1</b-form-checkbox>
            <b-form-checkbox value="2">Value 2</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
        <hr>

<!--        radio-->
        <b-form-group>
          <b-form-radio name="some-radios" value="A">Option A</b-form-radio>
          <b-form-radio name="some-radios" value="B">Option B</b-form-radio>
        </b-form-group>
        <hr>

<!--        file-->
        <b-form-group>
          <b-form-file
              placeholder="Choose a file..."
              drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
        <hr>

<!--        select-->
        <b-form-group>
          <b-form-select :options="options"></b-form-select>
        </b-form-group>
        <hr>

<!--        number-->
        <b-form-group>
          <b-form-spinbutton min="1" max="100"></b-form-spinbutton>
        </b-form-group>
        <hr>

<!--        rating-->
        <b-form-group>
          <b-form-rating></b-form-rating>
        </b-form-group>
        <hr>

        <!--        buttons-->
        <b-button type="button" variant="primary">Submit</b-button>
        <b-button type="button" variant="danger">Reset</b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "Form",
  data() {
    return {
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Selected Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled', disabled: true }
      ]
    }
  }
}
</script>

<style scoped>

</style>